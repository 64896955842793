import "core-js/modules/es.array.includes.js";
// @ is an alias to /src
import store from '@/store';
import { computed } from 'vue';
import { onMounted } from 'vue';
import qrcode from '@/assets/qrcode.png';
import rightimg from '@/assets/rightimg.png';
import word from '@/assets/word.png';
import ios from '@/assets/ios-black.png';
import android from '@/assets/android-black.png';
import beian from '@/assets/beian.png';
import quan from '@/assets/icon.png';
import ContactTip from '@/components/ContactTip';
import { ref } from 'vue';
import { saveAs } from 'file-saver';
export default {
  name: 'HomeView',
  components: {
    ContactTip
  },

  setup() {
    const user = computed(() => {
      return store.getters.getUserInfo;
    });
    const userAgent = navigator.userAgent.toLowerCase();
    onMounted(() => {
      const u = navigator.userAgent;
      const isAndroid = userAgent.indexOf('android') > -1 || u.indexOf('adr') > -1; //android终端

      const isHarmony = userAgent.includes('harmony'); //鸿蒙终端

      const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      if (isAndroid || isHarmony) {
        window.location.href = 'https://www.xqse.cn/down-page';
      }

      if (isIOS) {
        window.location.href = 'https://www.xqse.cn/ios';
      }
    });

    const bigImg = () => {
      big.value = !big.value;
    };

    const downloadAndroid = () => {
      saveAs('https://www.xqse.cn/download/androidRelease.apk', 'xiaoquan.apk');
    };

    const downIos = () => {
      window.open('https://apps.apple.com/cn/app/%E5%B0%8F%E5%B0%8F-%E5%93%81%E8%B4%A8%E4%BA%A4%E6%B5%81%E5%9C%88%E5%AD%90/id6505093720');
    };

    const tips = ref();
    const big = ref(false);
    return {
      user,
      qrcode,
      rightimg,
      ios,
      android,
      tips,
      bigImg,
      big,
      downloadAndroid,
      quan,
      word,
      downIos,
      beian
    };
  }

};