import icon from '@/assets/ios-icon.png';
import wechatjt from '@/assets/wechatjt.png';
import android from '@/assets/android.png';
import mid from '@/assets/ios-mid.png';
import safe from '@/assets/safe.png';
import mores from '@/assets/mores.png';
import down_back from '@/assets/invite/ios_down_back.png';
import safari from '@/assets/safari.png';
import liulan from '@/assets/invite/liulan.png';
import share_point from '@/assets/invite/share_point.png';
import { ref, onMounted } from 'vue';
export default {
  name: 'IosPage',

  setup() {
    document.getElementById('viewport').content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no;';
    const meta = document.createElement('meta'); // 设置meta标签的属性

    meta.setAttribute('name', 'apple-mobile-web-app-status-bar-style');
    meta.setAttribute('content', 'default');
    document.head.appendChild(meta);
    const meta1 = document.createElement('meta');
    meta1.setAttribute('name', 'apple-mobile-web-app-capable');
    meta1.setAttribute('content', 'yes');
    document.head.appendChild(meta1);
    const meta2 = document.createElement('meta');
    meta2.setAttribute('name', 'theme-color');
    meta2.setAttribute('content', '#0C0914');
    document.head.appendChild(meta2);
    const meta3 = document.createElement('meta');
    meta3.setAttribute('name', 'format-detection');
    meta3.setAttribute('content', 'telephone=no');
    document.head.appendChild(meta3);
    const meta4 = document.createElement('meta');
    meta4.setAttribute('name', 'format-detection');
    meta4.setAttribute('content', 'email=no');
    document.head.appendChild(meta4);
    const meta5 = document.createElement('meta');
    meta5.setAttribute('name', 'screen-orientation');
    meta5.setAttribute('content', 'portrait');
    document.head.appendChild(meta5);
    const meta6 = document.createElement('meta');
    meta6.setAttribute('name', 'full-screen');
    meta6.setAttribute('content', 'yes');
    document.head.appendChild(meta6);
    const meta7 = document.createElement('meta');
    meta7.setAttribute('name', 'browsermode');
    meta7.setAttribute('content', 'application');
    document.head.appendChild(meta7);
    const meta8 = document.createElement('meta');
    meta8.setAttribute('name', 'x5-orientation');
    meta8.setAttribute('content', 'portrait');
    document.head.appendChild(meta8);
    const meta9 = document.createElement('meta');
    meta9.setAttribute('name', 'x5-fullscreen');
    meta9.setAttribute('content', 'true');
    document.head.appendChild(meta9);
    const meta10 = document.createElement('meta');
    meta10.setAttribute('name', 'x5-page-mode');
    meta10.setAttribute('content', 'app');
    document.head.appendChild(meta10);
    const meta11 = document.createElement('meta');
    meta11.setAttribute('name', 'msapplication-tap-highlight');
    meta11.setAttribute('content', 'no');
    document.head.appendChild(meta11);
    const isWechat = ref(false);
    const show = ref(false);
    const isIos = ref(false);
    const userAgent = navigator.userAgent.toLowerCase();
    const match = userAgent.match(/MicroMessenger/i);

    if (match !== null && match.toString() === 'micromessenger') {
      isWechat.value = true;
    }

    isIos.value = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

    const close = () => {
      show.value = false;
    }; //下载按钮点击时调用方法


    const down = () => {
      if (isWechat.value) {
        show.value = true;
      } else {
        // window.open('itms-apps://itunes.apple.com/app/id' + )
        window.open('https://apps.apple.com/cn/app/%E5%B0%8F%E5%B0%8F-%E5%93%81%E8%B4%A8%E4%BA%A4%E6%B5%81%E5%9C%88%E5%AD%90/id6505093720');
      }
    };

    const toIos = () => {
      if (isWechat.value) {
        show.value = true;
      } else {
        down();
      }
    };

    onMounted(() => {
      const u = navigator.userAgent;
      const isAndroid = userAgent.indexOf('android') > -1 || u.indexOf('adr') > -1; //android终端

      const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      if (!isWechat.value && !isAndroid && !isIOS) {
        window.location.href = 'https://www.xqse.cn';
      }

      console.log(isAndroid);
      console.log(isIOS);
      console.log(isWechat.value);

      if (isAndroid) {
        window.location.href = 'https://www.xqse.cn/ios';
      }
    });
    return {
      icon,
      down,
      wechatjt,
      isWechat,
      show,
      android,
      safe,
      mores,
      down_back,
      safari,
      isIos,
      close,
      liulan,
      share_point,
      mid,
      toIos
    };
  }

};